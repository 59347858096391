import React from "react"
import Layout from "../components/layout"
import * as styles from "./contacts.module.css"
import Seo from "../components/seo"
import FluidImage from '../components/fluid_image'
import { Link, graphql } from "gatsby"
import ColoredSeparator from "../components/colored_sep"
import * as svgs from "../utils/svg_provider"
import { getImage } from "gatsby-plugin-image"


const sidep_link_style = {
	color: "#F15B5B",
	fontWeight: "bold",
	textDecoration: "none",
}

const SideP = () => {
	return (
		<>
		<div>
			<h2 className={styles.main_h1}>Sono una Graphic Designer <br></br> <i>(e amo esserlo!)</i></h2>
			<p>
				La mia strada creativa non è mai cambiata da quanto mia nonna mi mise una matita in mano quand'ero una bambina.
				Dopo il liceo mi sono subito buttata alla ricerca di prime esperienze lavorative. Ho iniziato a lavorare all'età di 19 anni, nel 2008,
				come receptionist per uno studio privato in modo da poter continuare gli studi con corsi di specializzazione serali.
				Nel 2010 ho iniziato la mia carriera come graphic designer, lavorando per "Today Pubblicità Italia",
				una pubblicazione giornalistica nel settore delle comunicazioni. Qui mi sono specializzata nel settore editoriale,
				e dopo 3 anni di layout sono stata trasferita al dipartimento grafico, dove ho iniziato a occuparmi anche di altri progetti,
				sempre mantenendo l'editoria come focus principale, lavorando a Pubblicità Italia (rivista nel settore della comunicazione).
				È proprio il lavoro in azienda che mi ha permesso di crescere e sperimentare su nuovi progetti, dalla brand identity al digital.
		    </p>
			<p>
				Nel 2019 ho avuto l'opportunità di collaborare con W-her, una applicazione dedicata alla mobilità della donna, come City Manager;
				ero incaricata di gestire le relazioni con la community e della grafica per i social networks e il sito web.
				È proprio questo lavoro che mi ha avvicinato molto al mondo digital e social, e da qui l’idea di aprire il mio profilo Instagram.
				All’inizio ideato solo come portfolio digitale, su un canale molto visibile a tutti,
				ma successivamente diventato punto di riferimento per una community di creativi, soprattutto emergenti e studenti,
				che hanno bisogno di qualche dritta o qualche consiglio in più.
		    </p>
			<p>
				Con molti progetti alle spalle e collaborazioni attive, mi trovo sempre a cercare nuovi stimoli, nuove ispirazioni.
				La curiosità e la voglia di fare sono alla base del mio lavoro, perché c'è sempre qualcosa di nuovo da imparare.
			</p>
			<p>
				Se doveste avere un proposta lavorativa o potesse interessarvi una collaborazione con me, non esitate a contattarmi qui
				<a href="mailto:2sdesign.santoni@gmail.com" rel="noopener noreferrer" style={sidep_link_style}><i> 2sdesign.santoni@gmail.com</i></a>.
			</p>
			<ul className={styles.socialist_wrapper}>
				<li>Social: </li>
				<li><a href="https://www.instagram.com/2s_design/" target="_blank" rel="noopener noreferrer">{svgs.icon_ig_black}</a></li>
				<li><a href="https://www.facebook.com/SamanthaSantoniDesign" target="_blank" rel="noopener noreferrer">{svgs.icon_fb_black}</a></li>
				<li><a href="https://www.linkedin.com/in/samantha-santoni-839b3537/" target="_blank" rel="noopener noreferrer">{svgs.icon_in_black}</a></li>
				<li><a href="https://www.behance.net/SamanthaSantoni" target="_blank" rel="noopener noreferrer">{svgs.icon_be_black}</a></li>
				<li><a href="mailto:2sdesign.santoni@gmail.com" target="_blank" rel="noopener noreferrer">{svgs.icon_mail_black}</a></li>
			</ul>
		</div>
		</>

	)
}

const ContactsPage = ({data}) => {
	return (
		<Layout>
			<Seo title="Contacs" subTitle="Samantha Santoni Portfolio"/>
			<div className={styles.pageWrapper}>
				<div className={styles.grid}>
					<div className={styles.cellitem}>
						{<SideP/>}
					</div>

					<div className={styles.cellitem}>
						{<FluidImage
							image={getImage(data.file)}
							altName="portrait contacts"
						 />}
					</div>

					{/*Usato display flex inline, in quanto causava problemi in Chrome, se usato su tutte le celle */}
					<div className={styles.cellitem + " " + styles.backLink_wrap}>
						<Link to="/" className={styles.backLink}>BACK TO HOME PAGE</Link>
						<ColoredSeparator color="black" />
					</div>
				</div>
			</div>
		</Layout>
	)
}


export default ContactsPage

export const pageQuery = graphql`
query {
	file(base: {eq: "2s_about.jpg"}) {
      childImageSharp {
        gatsbyImageData (
			quality: 80
		)
      }
    }
}
`
